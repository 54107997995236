.main_slider {
  height: 100vh;

  .swiper {
    height: 100%;

    &-pagination {
      display: flex;
      flex-direction: column;
      transform: translateY(-50%);
      position: absolute;
      right: 24px;
      top: 50%;
      z-index: 1;

      @media (max-width: 768px) {
        flex-direction: row;
        transform: translateX(-50%) translateY(0);
        bottom: 24px;
        top: initial;
        right: initial;
        left: 50%;
      }

      span {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'DM Sans';
        font-weight: 700;
        color: #9ca3af;
        margin: 5px 0;
        cursor: pointer;

        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }

        &::before {
          content: '';
          background: #ffffff;
          border-radius: 6px;
          width: 20px;
          height: 3px;
          visibility: hidden;
          margin: 0 6px 0 0;

          @media (max-width: 768px) {
            margin: 0 auto;
          }
        }

        &.swiper-pagination-bullet-active {
          font-size: 16;
          color: #ffffff;

          &::before {
            visibility: visible;
          }
        }
      }
    }
  }

  &-bk {
    filter: brightness(0.5);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-content {
    max-width: 1368px;
    margin: 150px auto 0 auto;
    padding: 0 24px;

    .title {
      font-size: 48px;
      font-family: 'DM Sans';
      font-weight: 700;
      line-height: 56px;
      color: #f5f6fa;
      max-width: 400px;

      @media (max-width: 435px) {
        font-size: 28px;
        line-height: 36px;
        max-width: initial;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 400px;

      .rate {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-family: 'DM Sans';
        font-weight: 400;
        line-height: 12px;
        color: #f5f6fa;

        .imdb {
          font-size: 14px;
          background: #e6b91d;
          font-family: 'DM Sans';
          font-weight: 700;
          padding: 5px 7px;
          border-radius: 4px;
          text-align: center;
          margin-right: 10px;
        }
      }

      .date {
        font-size: 14px;
        font-family: 'DM Sans';
        line-height: 56px;
        color: #f5f6fa;
        margin-left: 34px;
      }
    }

    .description {
      font-size: 16;
      font-family: 'DM Sans';
      font-weight: 400;
      line-height: 20px;
      color: #f5f6fa;
      max-width: 400px;

      @media (max-width: 992px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .watch-btn {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-top: 16px;
      height: 40px;
      border: initial;
      background: #be123c;
      border-radius: 6px;
      font-family: 'DM Sans';
      font-weight: 700;
      line-height: 24px;
      padding: 0 16px;
      text-transform: uppercase;
      color: #f5f6fa;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }
    }

    .watch-btn,
    .description,
    .info,
    .title {
      opacity: 0;
      transition: transform 0.5s ease, opacity 0.5s ease;
      transform: translateX(-100px);
    }

    &.active {
      .watch-btn,
      .description,
      .info,
      .title {
        opacity: 1;
        transform: translateY(0);
      }

      .title,
      .info {
        transition-delay: 0.3s, 0.3s;
      }

      .description {
        transition-delay: 0.5s, 0.6s;
      }

      .watch-btn {
        transition-delay: 0.7s, 0.9s;
      }
    }
  }
}
