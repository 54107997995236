.movie {
  &-grid {
    max-width: 1368px;
    margin: 0 auto;
    padding: 0 24px;

    &-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
      }
    }
  }
}

.show-more {
  font-size: 16;
  font-family: 'DM Sans';
  font-weight: 700;
  line-height: 12px;
  color: #111827;
  border: initial;
  background: transparent;
  border: 2px solid #d1d5db;
  border-radius: 6px;
  padding: 15px 20px;
  margin: 25px auto 0 auto;
  display: block;
  cursor: pointer;
}
