* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  background-color: #f5f6fa;
}
