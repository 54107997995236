.detail {
  &-banner {
    filter: brightness(0.4);
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
  }

  &-movie {
    height: 100vh;
    max-width: 1368px;
    margin: 0 auto;
    padding: 0 24px;

    &-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-poster {
      min-width: 340px;
      height: 500px;
      margin-right: 48px;

      @media (max-width: 768px) {
        display: none;
      }

      .img {
        border-radius: 24px;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    &-info {
      max-width: 600px;
      overflow: hidden;

      .title {
        font-size: 42px;
        font-family: 'DM Sans';
        font-weight: 700;
        color: white;

        @media (max-width: 768px) {
          font-size: 24;
          text-align: center;
        }
      }

      .overview {
        font-size: 18;
        font-family: 'DM Sans';
        font-style: normal;
        color: white;

        @media (max-width: 768px) {
          font-size: 14px;
          text-align: center;
        }
      }

      .genres {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        margin: 12px 0;

        @media (max-width: 768px) {
          justify-content: center;
        }

        &-item {
          font-size: 14px;
          font-family: 'DM Sans';
          font-weight: 400;
          color: white;
          padding: 5px 10px;
          border: 1px solid white;
          border-radius: 12px;
        }
      }

      .actors {
        font-family: 'DM Sans';
        color: white;
        margin: 24px 0;

        @media (max-width: 768px) {
          margin: 12px 0;
        }

        &-title {
          font-size: 24;

          @media (max-width: 768px) {
            font-size: 20px;
          }
        }

        &-item {
          width: 100%;
        }

        &-img {
          width: 100%;
          height: 190px;
          object-fit: cover;

          @media (max-width: 768px) {
            height: 250px;
          }
        }

        &-name {
          font-size: 18;
          font-family: 'DM Sans';
          color: white;
          display: block;
          text-align: center;

          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.section-similar {
  max-width: 1368px;
  margin: 24px auto 0 auto;
  padding: 0 24px;

  &-title {
    font-size: 36px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    color: #000000;
    display: block;
    margin: 0 0 12px 0;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
