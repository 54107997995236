@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

.header {
  transition: background-color 0.35s ease;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  z-index: 5;

  &-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    max-width: 1368px;
    margin: 0 auto;
    padding: 0 24px;
    z-index: 99;
  }

  &.shrink {
    background-color: #040303;
  }

  &-logo {
    display: flex;
    align-items: center;

    @media (max-width: 435px) {
      justify-content: center;
      width: 100%;
    }

    @media (max-width: 768px) {
      img {
        width: 35px;
        height: 35px;
      }
    }

    a {
      font-size: 24;
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      color: #f5f6fa;
      margin: 0 0 0 24px;

      @media (max-width: 768px) {
        font-size: 18;
        margin: 0 0 0 12px;
      }
    }
  }

  &-search {
    display: flex;
    position: relative;
    border: 2px solid #d1d5db;
    border-radius: 6px;

    @media (max-width: 768px) {
      display: none;
    }

    input {
      font-size: 16;
      font-family: 'DM Sans', sans-serif;
      color: #f5f6fa;
      background: transparent;
      border: initial;
      padding: 6px 10px;
      width: 350px;

      @media (max-width: 992px) {
        width: 280px;
      }

      &::placeholder {
        opacity: 1;
        color: #f5f6fa;
      }

      &:focus {
        outline: initial;
      }
    }

    svg {
      font-size: 16;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 7px;
      color: #f5f6fa;
      cursor: pointer;
    }
  }

  &_navigation {
    display: flex;
    align-items: center;

    @media (max-width: 435px) {
      display: none;
    }

    li {
      margin: 0 20px 0 0;
      list-style: none;
      position: relative;

      &::after {
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        border-radius: 4px;
        background-color: #f5f6fa;
        bottom: -5px;
        left: 0;
        transform-origin: right;
      }

      &:hover::after,
      &.active::after {
        transform: scaleX(1);
        transform-origin: left;
      }

      &:last-child {
        margin: 0;
      }

      @media (max-width: 768px) {
        margin: 0 12px 0 0;
      }

      a {
        font-size: 16;
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        color: #f5f6fa;
      }
    }
  }
}

.search-type {
  height: 33px;
  border-left: 2px solid #f5f6fa;
  cursor: pointer;

  &-select {
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    color: #f5f6fa;
    background: transparent;
    border: initial;
    text-transform: uppercase;
    outline: initial;
    font-weight: 700;
    padding: 0 27px 0 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: '';
    position: relative;
    top: 6px;
  }

  &.inactive {
    pointer-events: none;
  }
}
